<template>
  <div v-if="magazines">
    <!-- <FloatWhatsapp v-if="this.$store.getters.getUserData" />
    <Chat v-if="this.$store.getters.getUserData" /> -->
    <section class="product-list-heading mb-0">
      <div class="box-my-account">
        <div class="lizpoint-lookbook">
          <div class="container-lg">
            <h1
              class="
                title
                d-flex
                align-items-center
                justify-content-center
                pt-lg-5
              "
            >
              Liz Magz
            </h1>
            <div class="row">
              <div class="col-md-12">
                <div class="row">
                  <!-- LizMagz 2 Lastest Articles Start -->
                  <div
                    class="col-md-6"
                    v-for="lmlastest in magazines.slice(0, 2)"
                    :key="lmlastest.title"
                  >
                    <router-link :to="'/lizmagz/' + lmlastest.slug">
                      <b-img-lazy :src="lmlastest.image_url" :alt="lmlastest.image_alternate" class="floatleft" />
                      <span class="badge badge-danger lizmagz-badge">
                        {{ $helpers.format.date(lmlastest.createdAt) }}
                      </span>
                      <span>
                        <span class="badge badge-danger mb-2">Our Latest Article</span>
                        <h5>{{ lmlastest.title }}</h5>
                        <p>
                          {{ lmlastest.short_description.slice(0, 125) }}...
                        </p>
                      </span>
                    </router-link>
                  </div>
                  <!-- LizMagz 2 Lastest Articles End -->
                  <div class="col-md-12 alert-alert-secondary" role="start">
                    <hr class="hr-divider-grey" />
                  </div>
                  <!-- LizMagz Rest of the Articles Start -->
                  <div
                    class="col-md-4"
                    v-for="lm in magazines.slice(2, 20)"
                    :key="lm.title"
                    style="border: 20px"
                  >
                    <router-link :to="'/lizmagz/' + lm.slug">
                      <div
                        class="card"
                        style="width: 100%; margin-bottom: 15px"
                      >
                        <b-img-lazy class="card-img-top" :src="lm.image_url" :alt="lm.image_alternate" />
                        <span class="badge badge-danger lizmagz-badge">
                          {{ $helpers.format.date(lm.createdAt) }}
                        </span>
                        <div class="card-body" style="margin-bottom: 12px">
                          <h5 style="margin-bottom: 10px">{{ lm.title }}</h5>
                          <p class="card-text">
                            {{ lm.short_description.slice(0, 125) }}..
                            <span class="badge badge-light">read more</span>
                          </p>
                        </div>
                      </div>
                    </router-link>
                  </div>
                  <!-- LizMagz Rest of the Articles End -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
// import FloatWhatsapp from "@/components/mains/FloatWhatsapp.vue";
// import Chat from "@/components/mains/Chat.vue";
import axios from "axios";
import moment from "moment";
import { setMetainfo } from "@/_helpers";

export default {
  name: "LizMagazinePage",
  components: {
    // FloatWhatsapp,
    // Chat,
  },
  metaInfo() {
    return setMetainfo("Liz Magz Official Blog Review & Tips and Trick | Whizliz.com", "Liz Magz. Kumpulan lengkap berita,informasi, review, dan tips seputar perhiasan emas dan berlian. Lebih baik ketimbang Orori. Budayakan Membaca")
  },
  data() {
    return {
      magazines: [],
      slickSettings: {
        centerMode: true,
        centerPadding: "0px",
        slidesToShow: 2,
        autoplay: true,
        autoplaySpeed: 2000,
        responsive: [
          {
            breakpoint: 769,
            settings: {
              slidesToShow: 2,
            },
          },
          {
            breakpoint: 481,
            settings: {
              slidesToShow: 1,
            },
          },
        ],
      },
    };
  },
  computed: {
    apiUrl() {
      return process.env.VUE_APP_API_URL;
    },
    apiKey() {
      return process.env.VUE_APP_API_KEY;
    },
  },
  mounted() {
    axios
      .get(this.apiUrl + "users/articles/all", {
        headers: { api_key: this.apiKey },
      })
      .then((response) => this.setMagazine(response.data.data))
      .catch((error) => console.log(error));
  },
  methods: {
    setMagazine(data) {
      this.magazines = data;
      setTimeout(() => {
        window.prerenderReady = true;
      }, 2000)
    },
    formatDate(value) {
      if (value) {
        moment.locale("id"); // set ke bahasa indonesia
        return moment(String(value)).format("D MMM").toString();
      }
      return value;
    },
  },
};
</script>

<style scoped>
.lizpoint-lookbook a {
  transition: all 0.3s ease-out 0s;
}
.lizpoint-lookbook img {
  transition: opacity 0.6s, box-shadow 0.3s, -webkit-filter 0.6s,
    -webkit-transform 0.6s;
  transition: filter 0.6s, opacity 0.6s, transform 0.6s, box-shadow 0.3s;
  transition: filter 0.6s, opacity 0.6s, transform 0.6s, box-shadow 0.3s,
    -webkit-filter 0.6s, -webkit-transform 0.6s;
}
.lizpoint-lookbook a:hover img {
  -webkit-transform: scale(1.05);
  -ms-transform: scale(1.05);
  transform: scale(1.05);
}

* {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
h1 {
  font-family: "Mazius";
  font-weight: normal;
  line-height: 66px;
  letter-spacing: 1px;
  color: #0c0c0c;
}
h5 {
  margin-bottom: 8px;
  font-size: 19px;
  font-weight: 700;
  color: #000;
}
p {
  font-size: 14px;
  color: #000;
}
.center {
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.floatleft {
  float: left;
  margin-right: 12px;
  margin-bottom: 2px;
  text-decoration: none;
}
img.floatleft {
  width: 100%;
  margin-bottom: 12px;
  border-radius: 5px 5px 0 0;
}
.col-md-4 {
  margin-bottom: 10px;
}

/* New Style for Article */
.hr-divider-red {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1.5px solid #fa5859;
}
.hr-divider-grey {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1.5px solid #777777;
}

/* lizmagz-badge */
.lizmagz-badge {
  position: absolute;
  left: 4px;
  top: 10px;
  background: #d89700;
  text-align: center;
  color: white;
  padding: 5px 10px;
  font-size: 12px;
}
.card .lizmagz-badge {
  left: -6px;
}
</style>